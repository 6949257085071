"use client";

import Script from "next/script";

import { EVENT_JOURNEY_STARTED } from "@shared/constants";

import DataLayer from "@client/classes/data-layer/data-layer";
import type { Props as JourneyProps } from "@client/classes/models/journey";

type Props = {
  journey: JourneyProps;
};

export default function Journey({ journey }: Props) {
  return (
    <Script
      id="analytics-journey"
      onReady={() => {
        /* Update "Journey" props */
        DataLayer.events.queue(EVENT_JOURNEY_STARTED, journey);
      }}
    >
      <></>
    </Script>
  );
}
